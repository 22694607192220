//
// Main
//

// Custom
.journal-post > div > img {
	max-width: 100%;
	cursor: pointer;
}

.journal-post > p > img {
	max-width: 100%;
	cursor: pointer;
}

.text-active {
	color: #009ef7!important;
}

// React-slick remove default arrow
.slick-prev:before {
	content: '' !important
}

// React-slick remove default arrow
.slick-next:before {
	content: '' !important
}

// React-slick
.slick-slide {
	padding: 30px !important;
}

.landing-slider-img {
	border-radius: 10px!important;
}

.ReactTags__remove {
	border: none;
	cursor: pointer;
	background: none;
	color: white;
	font-size: 16px;
}

/* Styles for suggestions */
.ReactTags__suggestions {
	position: absolute;
}

.ReactTags__suggestions ul {
	background: white;
	width: 200px;
}

.ReactTags__suggestions li {
	padding: 5px;
	margin: 0;
	text-decoration: underline;
	background: none;
	font-weight: 500;
	font-size: 1.075rem;
	font-family: Poppins, Helvetica, "sans-serif";
}

.ReactTags__suggestions li mark {
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	color: #009ef7;
	cursor: pointer;
}

// Dashboard SVG icons
.widget-dark {
	stroke:#ffffff!important;
}

.swal2-modal {
	background-color: var(--kt-body-bg)!important;
}

.deposit-1, .deposit-2 {
	fill:none;
	stroke:#344154;
	stroke-linecap:round;
	stroke-linejoin:round;
	stroke-width:5.5px;
}
.deposit-1{stroke-width:5.5px;}
.deposit-2{stroke-width:5.5px;}
.deposit-3{fill:#ff6d3b;}

.result-1{
	fill:none;
	stroke:#344154;
	stroke-linecap:round;
	stroke-linejoin:round;
	stroke-width:5.5px;
}
.result-2{fill:#009ef7;}

// Body
body {
	background-color: $page-bg;
}

.popover{
	min-width:300px;
	max-width:700px;
	min-height:50px;
}

// Body
body {
	background-color: var(--kt-page-bg);
}

// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	// Wrapper
	.wrapper {
		transition: padding-left get($aside-config, transition-speed) ease,  margin-right get($aside-config, transition-speed) ease;

		// Aside default enabled and aside fixed modes
		.aside-enabled.aside-fixed & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, width);
		}

		// Aside default enabled, aside fixed and aside minimize modes
		.aside-enabled.aside-fixed[data-kt-aside-minimize="on"] & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, minimized-width);
		}

		// Fixed header mode
		.header-fixed & {
			padding-top: get($header-config, fixed, height, desktop);
		}

		// Fixed header & fixed toolbar modes
		.header-fixed.toolbar-fixed & {
			padding-top: calc(#{get($header-config, fixed, height, desktop)} + var(--kt-toolbar-height));
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {
		// Fixed header mode
		.header-tablet-and-mobile-fixed & {
			padding-top: get($header-config, fixed, height, tablet-and-mobile);
		}

		// Fixed header & fixed toolbar modes
		.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
			padding-top: calc(#{get($header-config, fixed, height, tablet-and-mobile)} + var(--kt-toolbar-height-tablet-and-mobile));
		}
	}
}
